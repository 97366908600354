import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  TextField,
} from "@mui/material";
import { IAvailableFieldOptions, ISelectedFieldOptions } from "./App";
type ISidebar = {
  showDrawer: boolean;
  setShowDrawer: (show: boolean) => void;
  availableFieldOptions: IAvailableFieldOptions;
  showDep: boolean;
  setShowDep: (showDep: boolean) => void;
  setFilterOptions: (fo: ISelectedFieldOptions | undefined) => void;
  setHighlightOptions: (fo: ISelectedFieldOptions | undefined) => void;
};

const FilterGroup = styled("div")({
  padding: "5px",
  margin: "5px",
});

export const Sidebar: FC<ISidebar> = (props): ReactElement => {
  const [selectedTeamsHL, setSelectedTeamsHL] = useState<string[]>([]);
  const [selectedLabelsHL, setSelectedLabelsHL] = useState<string[]>([]);
  const [selectedStatusHL, setSelectedStatusHL] = useState<string[]>([]);

  const [selectedTeamsFi, setSelectedTeamsFi] = useState<string[]>([]);
  const [selectedLabelsFi, setSelectedLabelsFi] = useState<string[]>([]);
  const [selectedStatusFi, setSelectedStatusFi] = useState<string[]>([]);

  const [selectedVorhabenFi, setSelectedVorhabenFi] = useState<{
    key: string;
    summary: string;
    label: string;
  } | null>(null);
  const [selectedVorhabenHL, setSelectedVorhabenHL] = useState<{
    key: string;
    summary: string;
    label: string;
  } | null>(null);
  const [selectedFeatureFi, setSelectedFeatureFi] = useState<{
    key: string;
    summary: string;
    label: string;
  } | null>(null);
  const [selectedFeatureHL, setSelectedFeatureHL] = useState<{
    key: string;
    summary: string;
    label: string;
  } | null>(null);

  useEffect(() => {
    const filterOptions: ISelectedFieldOptions = {};

    if (selectedLabelsFi.length > 0) filterOptions.labels = selectedLabelsFi;
    if (selectedStatusFi.length > 0) filterOptions.status = selectedStatusFi;
    if (selectedTeamsFi.length > 0) filterOptions.teams = selectedTeamsFi;

    if (selectedVorhabenFi)
      filterOptions.vorhaben = {
        key: selectedVorhabenFi.key,
        summary: selectedVorhabenFi.summary,
      };

    if (selectedFeatureFi)
      filterOptions.feature = {
        key: selectedFeatureFi.key,
        summary: selectedFeatureFi.summary,
      };

    if (Object.keys(filterOptions).length > 0)
      props.setFilterOptions(filterOptions);
    else props.setFilterOptions(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLabelsFi,
    selectedStatusFi,
    selectedTeamsFi,
    selectedVorhabenFi,
    selectedFeatureFi,
  ]);

  useEffect(() => {
    const highlightOptions: ISelectedFieldOptions = {};

    if (selectedLabelsHL.length > 0) highlightOptions.labels = selectedLabelsHL;
    if (selectedStatusHL.length > 0) highlightOptions.status = selectedStatusHL;
    if (selectedTeamsHL.length > 0) highlightOptions.teams = selectedTeamsHL;

    if (selectedVorhabenHL)
      highlightOptions.vorhaben = {
        key: selectedVorhabenHL.key,
        summary: selectedVorhabenHL.summary,
      };
    if (selectedFeatureHL)
      highlightOptions.feature = {
        key: selectedFeatureHL.key,
        summary: selectedFeatureHL.summary,
      };

    if (Object.keys(highlightOptions).length > 0)
      props.setHighlightOptions(highlightOptions);
    else props.setHighlightOptions(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLabelsHL,
    selectedStatusHL,
    selectedTeamsHL,
    selectedVorhabenHL,
    selectedFeatureHL,
  ]);

  const handleTeamHLChange = (
    event: SelectChangeEvent<typeof selectedTeamsHL>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedTeamsHL(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLabelHLChange = (
    event: SelectChangeEvent<typeof selectedLabelsHL>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedLabelsHL(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleStatusHLChange = (
    event: SelectChangeEvent<typeof selectedStatusHL>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedStatusHL(typeof value === "string" ? value.split(",") : value);
  };

  const handleTeamFiChange = (
    event: SelectChangeEvent<typeof selectedTeamsFi>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedTeamsFi(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLabelFiChange = (
    event: SelectChangeEvent<typeof selectedLabelsFi>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedLabelsFi(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleStatusFiChange = (
    event: SelectChangeEvent<typeof selectedStatusFi>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedStatusFi(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Drawer
      anchor="left"
      open={props.showDrawer}
      onClose={() => props.setShowDrawer(false)}
    >
      <div style={{ minWidth: "200px", padding: "5px" }}>
        <FilterGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="showDep-select"
                  checked={props.showDep}
                  onChange={() => props.setShowDep(!props.showDep)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Show Dependencies"
            />
          </FormGroup>
        </FilterGroup>
        <Divider />
        <FilterGroup>
          Highlight
          <FormControl fullWidth>
            <InputLabel id="highlight-team-checkbox-label">Team</InputLabel>
            <Select
              labelId="highlight-team-checkbox-label"
              id="highlight-team-checkbox"
              multiple
              value={selectedTeamsHL}
              onChange={handleTeamHLChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {props.availableFieldOptions.teams.map((name) => (
                <MenuItem key={"highlight-" + name} value={name}>
                  <Checkbox checked={selectedTeamsHL.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="highlight-label-checkbox-label">Label</InputLabel>
            <Select
              labelId="highlight-label-checkbox-label"
              id="highlight-label-checkbox"
              multiple
              value={selectedLabelsHL}
              onChange={handleLabelHLChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {props.availableFieldOptions.labels.map((name) => (
                <MenuItem key={"highlight-" + name} value={name}>
                  <Checkbox checked={selectedLabelsHL.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="highlight-status-checkbox-label">Status</InputLabel>
            <Select
              labelId="highlight-status-checkbox-label"
              id="highlight-status-checkbox"
              multiple
              value={selectedStatusHL}
              onChange={handleStatusHLChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {props.availableFieldOptions.status.map((name) => (
                <MenuItem key={"highlight-" + name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            disablePortal
            id="highlight-combo-box-vorhaben"
            value={selectedVorhabenHL}
            options={props.availableFieldOptions.vorhaben.map((element) => {
              return {
                label: element.key + " " + element.summary,
                ...element,
              };
            })}
            renderInput={(params) => <TextField {...params} label="Vorhaben" />}
            onChange={(event, newValue) => {
              setSelectedVorhabenHL(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
          />
          <Autocomplete
            disablePortal
            id="highlight-combo-box-feature"
            value={selectedFeatureHL}
            options={props.availableFieldOptions.feature.map((element) => {
              return {
                label: element.key + " " + element.summary,
                ...element,
              };
            })}
            renderInput={(params) => <TextField {...params} label="Feature" />}
            onChange={(event, newValue) => {
              setSelectedFeatureHL(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
          />
        </FilterGroup>
        <Divider />
        <FilterGroup>
          Filter
          <FormControl fullWidth>
            <InputLabel id="filter-team-checkbox-label">Team</InputLabel>
            <Select
              labelId="filter-team-checkbox-label"
              id="filter-team-checkbox"
              multiple
              value={selectedTeamsFi}
              onChange={handleTeamFiChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {props.availableFieldOptions.teams.map((name) => (
                <MenuItem key={"filter-" + name} value={name}>
                  <Checkbox checked={selectedTeamsFi.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="filter-label-checkbox-label">Label</InputLabel>
            <Select
              labelId="filter-label-checkbox-label"
              id="filter-label-checkbox"
              multiple
              value={selectedLabelsFi}
              onChange={handleLabelFiChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {props.availableFieldOptions.labels.map((name) => (
                <MenuItem key={"filter-" + name} value={name}>
                  <Checkbox checked={selectedLabelsFi.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="filter-status-checkbox-label">Status</InputLabel>
            <Select
              labelId="filter-status-checkbox-label"
              id="filter-status-checkbox"
              multiple
              value={selectedStatusFi}
              onChange={handleStatusFiChange}
              renderValue={(selected) => selected.join(", ")}
              input={<OutlinedInput label="Tag" />}
            >
              {props.availableFieldOptions.status.map((name) => (
                <MenuItem key={"filter-" + name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            disablePortal
            id="filter-combo-box-vorhaben"
            value={selectedVorhabenFi}
            options={props.availableFieldOptions.vorhaben.map((element) => {
              return {
                label: element.key + " " + element.summary,
                ...element,
              };
            })}
            renderInput={(params) => <TextField {...params} label="Vorhaben" />}
            onChange={(event, newValue) => {
              setSelectedVorhabenFi(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
          />
          <Autocomplete
            disablePortal
            id="filter-combo-box-feature"
            value={selectedFeatureFi}
            options={props.availableFieldOptions.feature.map((element) => {
              return {
                label: element.key + " " + element.summary,
                ...element,
              };
            })}
            renderInput={(params) => <TextField {...params} label="Feature" />}
            onChange={(event, newValue) => {
              setSelectedFeatureFi(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
          />
        </FilterGroup>
        <Divider />
        <FilterGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox disabled />}
              label="Leere Zeilen ausblenden"
            />
            <FormControlLabel
              control={<Checkbox disabled />}
              label="ODER Verknüpfung der Kriterien"
            />
          </FormGroup>
        </FilterGroup>
      </div>
    </Drawer>
  );
};
