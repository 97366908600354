import React, { FC, ReactElement } from "react";

import Chip from "@mui/material/Chip";

type StatusProps = {
  status: {
    name: string;
    color: string;
  };
};
export const Status: FC<StatusProps> = (props): ReactElement => {
  let color:
    | "default"
    | "success"
    | "warning"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | undefined;
  switch (props.status.color) {
    case "blue-gray": {
      color = "default";
      break;
    }
    case "green": {
      color = "success";
      break;
    }
    case "yellow": {
      color = "warning";
      break;
    }
  }
  return (
    <Chip
      label={props.status.name}
      size="small"
      color={color}
      variant="outlined"
    />
  );
};
