import { w3cwebsocket as W3CWebSocket } from "websocket";
import { FEBoardData, IAvailableFieldOptions } from "../components/App";
import {
  Board,
  ClientResponse,
  ServerCommand,
  MoveItem,
  UserInfo,
} from "../types/Interface";

if (!process.env.REACT_APP_FLOWSERVERURL) {
  throw new Error("SERVER URL not set");
}
const client = new W3CWebSocket(process.env.REACT_APP_FLOWSERVERURL);

export default class Connector {
  static vorhabenIssueType = "Vorhaben";
  static featureIssueType = "Feature";

  constructor(
    dataUpdatedCb: (data: FEBoardData) => any,
    setUserInfo: (userInfo: UserInfo | undefined) => void,
    showNotification: (message: string) => void
  ) {
    client.onopen = () => {
      console.log("WebSocket Client Connected");
      const curUser = localStorage.getItem("userInfo");
      if (curUser) {
        console.log("found in local storage - setting user info");
        const userInfoObject = JSON.parse(curUser) as UserInfo;
        setUserInfo(userInfoObject);
        client.send(
          JSON.stringify({
            type: "Identify",
            payload: {
              token: userInfoObject.token,
            },
          })
        );
      }
    };
    client.onmessage = (message) => {
      console.log(new TextEncoder().encode(message.data as string).length);
      if (typeof message.data === "string" || message.data instanceof String) {
        const myData = JSON.parse(message.data as string) as ClientResponse;
        if (myData.type === "LoginResult") {
          if (
            myData.payload.loginResult?.ok &&
            myData.payload.loginResult.userInfo
          ) {
            localStorage.setItem(
              "userInfo",
              JSON.stringify(myData.payload.loginResult.userInfo)
            );
            setUserInfo(myData.payload.loginResult.userInfo);
            showNotification("Logged in successfully");
          } else if (
            myData.payload.loginResult?.ok === false &&
            myData.payload.loginResult.message
          ) {
            localStorage.removeItem("userInfo");
            setUserInfo(undefined);
            showNotification(myData.payload.loginResult.message);
          }
        } else if (myData.type === "DataUpdate" && myData.payload.boardData) {
          const newAttributes = this.extractFilterOptions(
            myData.payload.boardData as Board
          );
          dataUpdatedCb({
            ...myData.payload.boardData,
            availableFieldOptions: newAttributes,
          } as FEBoardData);
        } else if (
          myData.type === "ActionResult" &&
          myData.payload.actionResult
        ) {
          if (!myData.payload.actionResult.ok)
            showNotification(myData.payload.actionResult.message);
        } else if (myData.type === "Notification" && myData.payload.message) {
          showNotification(myData.payload.message);
        } else {
          console.log(myData);
          console.log("not understood.");
        }
        console.log(myData);
      }
    };
  }

  public async login(authCode: string): Promise<void> {
    await this.ensureConnected();
    const loginMessage: ServerCommand = {
      type: "Login",
      payload: { login: { code: authCode } },
    };
    client.send(JSON.stringify(loginMessage));
  }

  private ensureConnected(): Promise<void> {
    return new Promise(function (resolve, reject) {
      (function waitForCondition() {
        if (client.readyState === WebSocket.OPEN) return resolve();
        setTimeout(waitForCondition, 30);
      })();
    });
  }

  public reloadData = (token: string) => {
    const cmd: ServerCommand = {
      type: "Refresh",
      payload: { token: token },
    };
    client.send(JSON.stringify(cmd));
  };

  public moveItem = (move: MoveItem, token: string) => {
    const cmd: ServerCommand = {
      type: "MoveItem",
      payload: {
        moveItem: move,
        token: token,
      },
    };
    client.send(JSON.stringify(cmd));
  };

  public moveRowUp = (rowKey: string, token: string) => {
    const cmd: ServerCommand = {
      type: "MoveRow",
      payload: {
        moveRow: { rowKey: rowKey, direction: "UP" },
        token: token,
      },
    };
    client.send(JSON.stringify(cmd));
  };
  public moveRowDown = (rowKey: string, token: string) => {
    const cmd: ServerCommand = {
      type: "MoveRow",
      payload: {
        moveRow: { rowKey: rowKey, direction: "DOWN" },
        token: token,
      },
    };
    client.send(JSON.stringify(cmd));
  };

  private extractFilterOptions(board: Board): IAvailableFieldOptions {
    const teamsArray: string[] = [];
    const labelsArray: string[] = [];
    const statusArray: string[] = [];
    const vorhabenArray: { key: string; summary: string }[] = [];
    const featureArray: { key: string; summary: string }[] = [];

    for (const item in board.items) {
      board.items[item].teams.forEach((team) => {
        if (teamsArray.indexOf(team) < 0) teamsArray.push(team);
      });
      board.items[item].labels.forEach((label) => {
        if (labelsArray.indexOf(label) < 0) labelsArray.push(label);
      });
      if (statusArray.indexOf(board.items[item].status.name) < 0)
        statusArray.push(board.items[item].status.name);

      if (board.items[item].type === Connector.featureIssueType)
        featureArray.push({
          key: board.items[item].key,
          summary: board.items[item].content,
        });
    }

    board.rows.forEach((row) => {
      vorhabenArray.push({
        key: row.key,
        summary: row.content,
      });
    });

    return {
      teams: teamsArray,
      labels: labelsArray,
      status: statusArray,
      vorhaben: vorhabenArray,
      feature: featureArray,
    };
  }
}
