import React, { FC, ReactElement } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Task } from "./../Task";
import { ColumnItem, TaskItem } from "../../types/Interface";
import { ISelectedFieldOptions, TaskBoxRef } from "./../App";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";

interface TaskListProps {
  isDraggingOver: boolean;
}

type ICellProps = {
  column: ColumnItem;
  tasks: TaskItem[];
  cellId: string;
  refs: TaskBoxRef[];
  highlightOptions?: ISelectedFieldOptions;
  filterOptions?: ISelectedFieldOptions;
  isDragDisabled: boolean;
};

export const Cell: FC<ICellProps> = (props): ReactElement => {
  const theme = useTheme();

  const Container = styled("div")({
    margin: "8px",
    border: "1px solid lightgrey",
    backgroundColor: "white",
    borderRadius: "2px",
    width: "220px",
    display: "flex",
    flexDirection: "column",
  });

  const Title = styled("div")({
    padding: "8px",
    backgroundColor: theme.palette.secondary.main,
  });

  const Subtitle = styled("div")({
    padding: "4px",
    backgroundColor: theme.palette.secondary.main,
    fontSize: "0.8em",
  });

  const TaskList = styled("div", {
    shouldForwardProp: (prop) => prop !== "isDraggingOver",
  })<TaskListProps>(({ isDraggingOver }) => {
    let bgcolor = "white";
    if (isDraggingOver) {
      bgcolor = theme.palette.secondary.light;
    }
    return {
      backgroundColor: bgcolor,
      padding: "8px",
      transition: "backgroundColor 0.2s ease",
      flexGrow: 1,
      minHeight: "100px",
    };
  });

  return (
    <Container>
      <Title>{props.column.title}</Title>
      <Subtitle>
        {props.column.startDate
          ? props.column.startDate + " to " + props.column.endDate
          : "?"}
      </Subtitle>
      <Droppable droppableId={props.cellId}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {props.tasks.map((task, index) => {
              return (
                <div
                  key={"boxOf" + task.key}
                  ref={props.refs.find((cell) => cell.key === task.key)?.ref}
                >
                  <Task
                    key={task.key}
                    task={task}
                    index={index}
                    highlightOptions={props.highlightOptions}
                    filterOptions={props.filterOptions}
                    isDragDisabled={props.isDragDisabled}
                  />
                </div>
              );
            })}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
};
