import React, { FC, ReactElement } from "react";

import Chip from "@mui/material/Chip";
import { Avatar } from "@mui/material";

type TeamListProps = {
  name: string | undefined;
  avatarUrl: string | undefined;
};
export const Assignee: FC<TeamListProps> = (props): ReactElement => {
  return (
    <Chip
      avatar={<Avatar alt={props.name} src={props.avatarUrl} />}
      label={props.name ? props.name : "niemand"}
      variant="outlined"
    />
  );
};
