import React, { FC, ReactElement } from "react";
import { styled } from "@mui/system";
import moment, { Moment } from "moment";
import { ColumnItem } from "../../types/Interface";

type IDateHeaderProps = {
  solutionSprint: ColumnItem;
};

export const DateHeader: FC<IDateHeaderProps> = (props): ReactElement => {
  const isWeekend = (date: Moment): boolean =>
    moment(date).day() > 4 ? true : false;

  const getDateRow = (): {
    from: number;
    to: number;
    key: string;
    caption: string;
    isWeekend: boolean;
  }[] => {
    let currentDate = moment(props.solutionSprint.startDate);
    const endDate = moment(props.solutionSprint.endDate);
    const cols = [];
    let index = 1;
    while (endDate.isSameOrAfter(currentDate)) {
      cols.push({
        from: index,
        to: index,
        key: currentDate.toString(),
        caption: currentDate.format("dd DD.MM"),
        isWeekend: isWeekend(currentDate),
      });
      index++;

      currentDate = currentDate.add(1, "days");
    }
    return cols;
  };

  const Item = styled("div")(() => ({
    textAlign: "center",
    border: "1px solid black",
    display: "flex",
  }));

  return (
    <>
      {getDateRow().map((row) => (
        <Item
          style={{
            gridColumnStart: row.from,
            gridColumnEnd: row.to,
            gridRowStart: 2,
            gridRowEnd: 2,
            width: "35px",
            fontSize: "0.8em",
            backgroundColor: row.isWeekend ? "LightGray" : "DarkGrey",
          }}
          key={row.key}
        >
          {row.caption}
        </Item>
      ))}
    </>
  );
};
