import React, { FC, ReactElement, useState } from "react";
import moment from "moment";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { styled } from "@mui/system";
import { FEBoardData, ISelectedFieldOptions, TaskBoxRef } from "../App";
import { Xwrapper } from "react-xarrows";
import { SprintContainer } from "./SprintContainer";
import { DateHeader } from "./DateHeader";
import { ColumnItem } from "../../types/Interface";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Arrows } from "../Arrows";

type IBoardProps = {
  boardData: FEBoardData;
  refs: TaskBoxRef[];
  highlightOptions?: ISelectedFieldOptions;
  filterOptions?: ISelectedFieldOptions;
  isDragDisabled: boolean;
  showDep: boolean;
};

export const SolutionBoard: FC<IBoardProps> = (props): ReactElement => {
  const [selectedSolution, setSelectedSolution] = useState<ColumnItem>(
    props.boardData.columns[1]
  );

  const totalDays =
    moment(selectedSolution.endDate).diff(
      moment(selectedSolution.startDate),
      "days"
    ) + 1;

  const GridContainer = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "repeat(" + totalDays + ", auto)",
    gridTemplateRows: 6,
    justifyContent: "start",
  }));

  const onDragEnd = (result: DropResult) => {
    console.log("not implemented");
    console.log(result);
  };
  const onDragStart = (initial: DragStart) => {
    console.log("not implemented");
    console.log(initial);
  };

  return (
    <div>
      <FormControl style={{ margin: "5px" }}>
        <InputLabel id="select-version-label">Version</InputLabel>
        <Select
          labelId="select-version-label"
          id="version-select"
          value={props.boardData.columns.findIndex(
            (col) => col.key === selectedSolution.key
          )}
          label="Age"
          onChange={(event) => {
            setSelectedSolution(
              props.boardData.columns[event.target.value as unknown as number]
            );
          }}
        >
          {props.boardData.columns.map((col, index) => (
            <MenuItem key={index} value={index}>
              {col.title} &nbsp;
              <div style={{ fontSize: "0.8em" }}>
                {moment(col.startDate).format("DD.MM.YYYY") +
                  " bis " +
                  moment(col.endDate).format("DD.MM.YYYY")}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Xwrapper>
          <GridContainer style={{ overflowY: "scroll" }}>
            <DateHeader solutionSprint={selectedSolution} />
            {props.boardData.teamboards.map((teamboard, index) => {
              return teamboard.sprints.map((sprint) => {
                if (
                  sprint.startDate &&
                  sprint.endDate &&
                  moment(sprint.endDate).isAfter(
                    moment(selectedSolution.startDate)
                  ) &&
                  moment(sprint.startDate).isBefore(
                    moment(selectedSolution.endDate)
                  )
                ) {
                  return (
                    <SprintContainer
                      refs={props.refs}
                      highlightOptions={props.highlightOptions}
                      filterOptions={props.filterOptions}
                      isDragDisabled={props.isDragDisabled}
                      boardData={props.boardData}
                      sprint={sprint}
                      row={index + 3}
                      solutionSprintStartDate={
                        new Date(selectedSolution.startDate)
                      }
                      maxDays={totalDays}
                      key={"SprintContainer" + sprint.id}
                    />
                  );
                } else {
                  return undefined;
                }
              });
            })}
          </GridContainer>
          <Arrows
            showDep={props.showDep}
            items={props.boardData.items}
            taskBoxRefs={props.refs}
          />
        </Xwrapper>
      </DragDropContext>
    </div>
  );
};
