import React, { FC, ReactElement } from "react";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import { TaskItem } from "../types/Interface";
import { TeamList } from "./TeamList";
import { Labels } from "./Labels";
import { Status } from "./Status";
import { ISelectedFieldOptions } from "./App";
import { Vorhaben } from "./Vorhaben";
import { Feature } from "./Feature";
import { IconButton } from "@mui/material";

interface ITaskProps {
  index: number;
  task: TaskItem;
  highlightOptions?: ISelectedFieldOptions;
  filterOptions?: ISelectedFieldOptions;
  isDragDisabled: boolean;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  highlight: boolean
) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(highlight && {
    background: "PaleVioletRed",
  }),
  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const allStringsInArray = (
  searchFor: string[],
  searchIn: string[]
): boolean => {
  return searchFor
    .map((myString) => searchIn.includes(myString))
    .reduce((prev, cur) => prev && cur);
};

export const Task: FC<ITaskProps> = (props): ReactElement | null => {
  const matchesCriteria = (criteria: ISelectedFieldOptions): boolean => {
    let showTask = true;
    if (criteria) {
      if (criteria.teams) {
        if (!allStringsInArray(criteria.teams, props.task.teams)) {
          return false;
        }
      }
      if (criteria.labels) {
        if (!allStringsInArray(criteria.labels, props.task.labels)) {
          return false;
        }
      }
      if (criteria.status) {
        if (!criteria.status.includes(props.task.status.name)) {
          return false;
        }
      }
      if (criteria.types) {
        if (!criteria.types.includes(props.task.type)) {
          return false;
        }
      }
      if (criteria.vorhaben) {
        if (
          !props.task.vorhaben ||
          !(criteria.vorhaben.key === props.task.vorhaben.key)
        ) {
          return false;
        }
      }
      if (criteria.feature) {
        if (
          !props.task.feature ||
          !(criteria.feature.key === props.task.feature.key)
        ) {
          return false;
        }
      }
    }
    return showTask;
  };

  if (!props.filterOptions || matchesCriteria(props.filterOptions)) {
    return (
      <Draggable
        draggableId={props.task.key}
        index={props.index}
        isDragDisabled={props.isDragDisabled}
      >
        {(provided, snapshot) => {
          return (
            <Card
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                props.highlightOptions
                  ? matchesCriteria(props.highlightOptions)
                  : false
              )}
              sx={{ margin: "5px" }}
              id={props.task.key}
            >
              <CardContent>
                <Status status={props.task.status} />
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {props.task.key} - {props.task.type}
                </Typography>
                {props.task.content}
                <Labels labels={props.task.labels} />
              </CardContent>
              <CardActions>
                <IconButton
                  onClick={() => window.open(props.task.linkTo, "_blank")}
                >
                  <LinkIcon fontSize="small" />
                </IconButton>
                <TeamList teams={props.task.teams} />
                {props.task.feature && (
                  <Feature
                    issuekey={props.task.feature.key}
                    link={props.task.feature.linkTo}
                    summary={props.task.feature.summary}
                  />
                )}
                {props.task.vorhaben && (
                  <Vorhaben
                    issuekey={props.task.vorhaben.key}
                    link={props.task.vorhaben.linkTo}
                    summary={props.task.vorhaben.summary}
                  />
                )}
              </CardActions>
            </Card>
          );
        }}
      </Draggable>
    );
  } else {
    return null;
  }
};
