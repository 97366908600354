import React, { FC, ReactElement } from "react";

import Chip from "@mui/material/Chip";

type VorhabenProps = {
  issuekey: string;
  summary: string;
  link: string;
};

const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + ".." : str;
};

export const Vorhaben: FC<VorhabenProps> = (props): ReactElement => {
  return (
    <Chip
      label={props.issuekey + " " + truncate(props.summary, 7)}
      size="small"
      variant="filled"
      component="a"
      href={props.link}
      clickable
      color="warning"
      title={props.summary}
      target="_blank"
    />
  );
};
