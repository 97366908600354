import React, { FC, ReactElement, useEffect, useState } from "react";
import Xarrow from "react-xarrows";
import { ItemDict } from "../types/Interface";
import { DepArrow, TaskBoxRef } from "./App";

type IArrows = { showDep: boolean; items: ItemDict; taskBoxRefs: TaskBoxRef[] };

export const Arrows: FC<IArrows> = (props): ReactElement => {
  const [arrows, setArrows] = useState<DepArrow[]>();

  useEffect(() => {
    const newArrows: DepArrow[] = [];
    for (const itemIndex in props.items) {
      const item = props.items[itemIndex];
      if (item.links) {
        item.links.forEach((link) => {
          if (link.outwardIssue) {
            const outwardIssueKey = link.outwardIssue.key;
            const itemRef = props.taskBoxRefs.find((cf) => cf.key === item.key);
            const linkedItemRef = props.taskBoxRefs.find(
              (cf) => cf.key === outwardIssueKey
            );
            if (
              itemRef &&
              linkedItemRef &&
              !(itemRef.key === linkedItemRef.key)
            ) {
              newArrows.push({
                from: itemRef.ref,
                to: linkedItemRef.ref,
                label: link.type.name,
              });
            } /*else {
              console.log("Not found ref", item.key, link.outwardIssue.key);
            }*/
          }
        });
      }
    }
    setArrows(newArrows);
  }, [props.items, props.taskBoxRefs]);

  useEffect(() => {
    //arrows.
  }, [arrows]);

  return (
    <>
      {props.showDep &&
        arrows &&
        arrows.map((arrow, index) => {
          //console.log(arrow);
          if (
            arrow.from &&
            arrow.to &&
            arrow.from.current &&
            arrow.to.current
          ) {
            return (
              <Xarrow
                start={arrow.from}
                end={arrow.to}
                key={index}
                color="DimGray"
                labels={
                  <div
                    style={{
                      backgroundColor: "rgba(220,160,140,0.8)",
                      padding: "1px",
                      border: "1px solid black",
                    }}
                  >
                    {arrow.label}
                  </div>
                }
                strokeWidth={2}
              />
            );
          } else {
            return undefined;
          }
        })}
    </>
  );
};
