import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, ReactElement } from "react";
import { Cell } from "./Cell";
import { Board } from "../../types/Interface";
import LinkIcon from "@mui/icons-material/Link";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Assignee } from "./../Assignee";
import { ISelectedFieldOptions, TaskBoxRef } from "./../App";

interface MoveFunction {
  (rowId: string): void;
}

type IRowProps = {
  boardData: Board;
  moveUp: MoveFunction;
  moveDown: MoveFunction;
  refs: TaskBoxRef[];
  highlightOptions?: ISelectedFieldOptions;
  filterOptions?: ISelectedFieldOptions;
  isDragDisabled: boolean;
};

const rowStyle: React.CSSProperties = {
  display: "block",
};

const columnStyle: React.CSSProperties = {
  display: "flex",
};

export const Row: FC<IRowProps> = (props): ReactElement => {
  const theme = useTheme();

  return (
    <div style={rowStyle}>
      {props.boardData.rows.map((row, index) => {
        if (
          !props.filterOptions ||
          (props.filterOptions?.vorhaben &&
            props.filterOptions.vorhaben.key === row.key)
        ) {
          const columns = props.boardData.columns.map((column, index) => {
            let thisCell = props.boardData.cells.find(
              (cell) => cell.key === row.key + column.key
            );
            if (thisCell) {
              return (
                <Cell
                  key={thisCell.key}
                  column={column}
                  tasks={thisCell.items.map(
                    (itemKey) => props.boardData.items[itemKey]
                  )}
                  cellId={thisCell.key}
                  refs={props.refs}
                  highlightOptions={props.highlightOptions}
                  filterOptions={props.filterOptions}
                  isDragDisabled={props.isDragDisabled}
                />
              );
            } else {
              throw new Error("Cell not found.");
            }
          });
          columns.unshift(
            <Card
              sx={{
                margin: "5px",
                width: "200px",
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              }}
              key={row.key}
            >
              <CardContent>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {row.key} - {row.type}
                </Typography>
                {row.content}
                <br />
                <Assignee
                  name={row.assignee?.name}
                  avatarUrl={row.assignee?.avatar}
                />
              </CardContent>
              <CardActions sx={{ textAlign: "center" }}>
                <div style={{ margin: "auto auto" }}>
                  <IconButton onClick={() => window.open(row.linkTo, "_blank")}>
                    <LinkIcon />
                  </IconButton>
                  <IconButton
                    disabled={
                      index === 0 || index > props.boardData.rows.length - 2
                        ? true
                        : false
                    }
                    onClick={() => {
                      props.moveUp(row.key);
                    }}
                  >
                    <ArrowUpwardIcon color="info" />
                  </IconButton>

                  <IconButton
                    disabled={
                      index > props.boardData.rows.length - 3 ? true : false
                    }
                    onClick={() => {
                      if (props.moveDown) props.moveDown(row.key);
                    }}
                  >
                    <ArrowDownwardIcon color="info" />
                  </IconButton>
                </div>
              </CardActions>
            </Card>
          );
          return (
            <div style={columnStyle} key={row.key}>
              {columns}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
