import React, { FC, ReactElement } from "react";

import Chip from "@mui/material/Chip";

type LabelsProps = {
  labels: string[];
};
export const Labels: FC<LabelsProps> = (props): ReactElement => {
  return (
    <div>
      {props.labels.map((label, index) => {
        return <Chip label={label} size="small" key={index} />;
      })}
    </div>
  );
};
