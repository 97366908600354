import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  CircularProgress,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { UserInfo } from "../types/Interface";
import { BoardType } from "./App";

type IAppBarProps = {
  isTempData: boolean;
  reloadData: () => void;
  toogleDrawer: () => void;
  userInfo: UserInfo | undefined;
  setUserInfo: (userInfo: UserInfo | undefined) => void;
  selectedBoard: BoardType;
  setSelectedBoard: (boardType: BoardType) => void;
};
export const ButtonAppBar: React.FC<IAppBarProps> = (
  props
): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props.toogleDrawer}
          >
            <MenuIcon />
          </IconButton>

          <FormControl sx={{ marginRight: "5px" }}>
            <InputLabel id="demo-simple-select-label">Board View</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{ backgroundColor: "white" }}
              value={props.selectedBoard}
              label="Selected Board"
              onChange={(event: SelectChangeEvent) => {
                props.setSelectedBoard(event.target.value as BoardType);
              }}
            >
              <MenuItem value={"FeatureBoard"}>Feature Board</MenuItem>
              <MenuItem value={"SolutionsBoard"}>Solution Board</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Flowboards App
          </Typography>
          {props.isTempData && (
            <>
              <CircularProgress color="inherit" size={"1rem"} />
              <span>Jira Sync in progress...</span>
            </>
          )}
          <Button
            variant="contained"
            endIcon={<RefreshIcon />}
            onClick={props.reloadData}
          >
            Reload Data from JIRA
          </Button>
          {props.userInfo && (
            <>
              <IconButton onClick={handleMenu}>
                <Avatar
                  src={props.userInfo.avatarUrl}
                  style={{
                    margin: "10px",
                    width: "32px",
                    height: "32px",
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    localStorage.clear();
                    props.setUserInfo(undefined);
                    handleClose();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
          {!props.userInfo && (
            <Button
              color="inherit"
              onClick={() => {
                if (
                  process.env.REACT_APP_ATL_CLIENT_ID &&
                  process.env.REACT_APP_APP_URI
                ) {
                  window.location.replace(
                    "https://auth.atlassian.com/authorize?audience=api.atlassian.com&" +
                      "client_id=" +
                      process.env.REACT_APP_ATL_CLIENT_ID +
                      "&" +
                      "scope=" +
                      "read:jira-work%20write:jira-work%20read:jira-user&" +
                      "redirect_uri=" +
                      process.env.REACT_APP_APP_URI +
                      "&" +
                      "state=LSKJRFOWJFKL)98324231439DFEFSDEFK&" +
                      "response_type=code&" +
                      "prompt=consent"
                  );
                } else {
                  console.log("not set.");
                }
              }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
