import React, { FC, ReactElement } from "react";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

type TeamListProps = {
  teams: string[];
};
export const TeamList: FC<TeamListProps> = (props): ReactElement => {
  return (
    <Stack direction="row" spacing={1}>
      {props.teams.map((team) => {
        return <Chip label={team} key={team} size="small" />;
      })}
    </Stack>
  );
};
