import React, {
  createRef,
  FC,
  ReactElement,
  RefObject,
  useEffect,
  useState,
} from "react";
import Connector from "../dataprovider/Connector";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { Board as BoardData, MoveItem, UserInfo } from "../types/Interface";
import { ButtonAppBar } from "./AppBar";
import { Sidebar } from "./Sidebar";
import "./App.css";
import { FeatureBoard } from "./FeatureBoard/FeatureBoard";
import { SolutionBoard } from "./SolutionsBoard/SolutionBoard";

export type DepArrow = {
  from: RefObject<HTMLDivElement>;
  to: RefObject<HTMLDivElement>;
  label: string;
};
export type TaskBoxRef = {
  key: string;
  ref: RefObject<HTMLDivElement>;
};
export type ISelectedFieldOptions = {
  teams?: string[];
  status?: string[];
  labels?: string[];
  types?: string[];
  vorhaben?: { key: string; summary: string };
  feature?: { key: string; summary: string };
};
export type IAvailableFieldOptions = {
  teams: string[];
  status: string[];
  labels: string[];
  vorhaben: { key: string; summary: string }[];
  feature: { key: string; summary: string }[];
};

export type BoardType = "FeatureBoard" | "SolutionsBoard";

export type FEBoardData = BoardData & {
  availableFieldOptions: IAvailableFieldOptions;
};

const App: FC = (): ReactElement => {
  const [boardData, setBoardData] = useState<FEBoardData>({
    cells: [],
    rows: [],
    columns: [],
    items: {},
    isTempData: false,
    availableFieldOptions: {
      feature: [],
      labels: [],
      status: [],
      teams: [],
      vorhaben: [],
    },
    teamboards: [],
  });
  const [showDep, setShowDep] = useState<boolean>(false);
  const [connector, setConnector] = useState<Connector>();
  const [activeBackdrop, setActiveBackdrop] = useState(false);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isActiveSnackbar, setIsActiveSnackbar] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState("");

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [selectedBoard, setSelectedBoard] = useState<BoardType>("FeatureBoard");

  const [highlightOptions, setHighlightOptions] =
    useState<ISelectedFieldOptions>();
  const [filterOptions, setFilterOptions] = useState<ISelectedFieldOptions>();

  useEffect(() => {
    console.log(highlightOptions);
  }, [highlightOptions]);

  const createCellRefs = (boardData: BoardData) => {
    const myrefs: TaskBoxRef[] = [];
    for (const item in boardData.items) {
      myrefs.push({ key: boardData.items[item].key, ref: createRef() });
    }
    return myrefs;
  };
  const taskBoxRefs = createCellRefs(boardData);

  const openSnackBar = (msg = "Something went wrong...") => {
    setNotificationMessage(msg);
    setIsActiveSnackbar(true);
  };

  const closeSnackBar = () => {
    setIsActiveSnackbar(false);
  };

  const reloadData = () => {
    if (connector && userInfo) {
      connector.reloadData(userInfo.token);
      setActiveBackdrop(true);
    } else openSnackBar("Not logged in.");
  };

  const dataUpdatedCb = (data: FEBoardData): void => {
    console.log("new data incomming");
    setBoardData(data);
  };

  useEffect(() => {
    console.log(boardData);
    setActiveBackdrop(false);
  }, [boardData]);

  useEffect(() => {
    if (!userInfo) {
      const queryParams = new URLSearchParams(window.location.search);
      const authCode = queryParams.get("code");
      if (authCode && connector) {
        console.log("code found. trying to login..");
        connector.login(authCode);
      }
    }
  }, [connector, userInfo]);

  useEffect(() => {
    const myCon = new Connector(dataUpdatedCb, setUserInfo, openSnackBar);
    setConnector(myCon);
  }, []);

  return (
    <div className="App">
      <ButtonAppBar
        isTempData={boardData.isTempData}
        reloadData={reloadData}
        toogleDrawer={() => setShowDrawer(!showDrawer)}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        selectedBoard={selectedBoard}
        setSelectedBoard={setSelectedBoard}
      />
      <Sidebar
        showDep={showDep}
        setShowDep={setShowDep}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        availableFieldOptions={boardData.availableFieldOptions}
        setFilterOptions={setFilterOptions}
        setHighlightOptions={setHighlightOptions}
      />

      <div>
        {selectedBoard === "FeatureBoard" && (
          <FeatureBoard
            boardData={boardData}
            taskBoxRefs={taskBoxRefs}
            moveRowUpFn={(key: string) => {
              if (connector && userInfo)
                connector.moveRowUp(key, userInfo.token);
              else openSnackBar("Not logged in.");
            }}
            moveRowDownFn={(key: string) => {
              if (connector && userInfo)
                connector.moveRowDown(key, userInfo.token);
              else openSnackBar("Not logged in.");
            }}
            showDep={showDep}
            moveItemFn={(move: MoveItem) => {
              if (connector && userInfo)
                connector.moveItem(move, userInfo.token);
              else openSnackBar("Not logged in.");
            }}
            highlightOptions={highlightOptions}
            filterOptions={filterOptions}
            isDragDisabled={userInfo ? false : true}
          />
        )}
        {selectedBoard === "SolutionsBoard" && (
          <SolutionBoard
            boardData={boardData}
            refs={taskBoxRefs}
            showDep={showDep}
            highlightOptions={highlightOptions}
            filterOptions={filterOptions}
            isDragDisabled={true}
          />
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={activeBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={isActiveSnackbar}
        onClose={closeSnackBar}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default App;
