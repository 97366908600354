import React, { FC, ReactElement } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Task } from "../Task";
import { Sprint, TaskItem } from "../../types/Interface";
import { ISelectedFieldOptions, TaskBoxRef } from "../App";
import { styled } from "@mui/system";
import { FEBoardData } from "../App";
import moment from "moment";
import { useTheme } from "@mui/material";

type ISprintContainerProps = {
  sprint: Sprint;
  row: number;
  solutionSprintStartDate: Date;
  maxDays: number;
  boardData: FEBoardData;
  refs: TaskBoxRef[];
  highlightOptions?: ISelectedFieldOptions;
  filterOptions?: ISelectedFieldOptions;
  isDragDisabled: boolean;
};

export const SprintContainer: FC<ISprintContainerProps> = (
  props
): ReactElement => {
  const theme = useTheme();

  interface SprintProps {
    isDraggingOver: boolean;
  }

  const Sprint = styled("div", {
    shouldForwardProp: (prop) => prop !== "isDraggingOver",
  })<SprintProps>(({ isDraggingOver }) => {
    let bgcolor = "white";
    if (isDraggingOver) {
      bgcolor = "skyblue";
    }
    return {
      backgroundColor: bgcolor,
      padding: "8px",
      transition: "backgroundColor 0.2s ease",
      flexGrow: 1,
      minHeight: "100px",
    };
  });

  const SprintContainer = styled("div")(() => ({
    textAlign: "center",
    border: "1px solid grey",
    backgroundColor: "white",
    margin: "3px",
    display: "flex",
    flexDirection: "column",
  }));

  const Title = styled("div")({
    padding: "8px",
    backgroundColor: theme.palette.secondary.main,
  });

  const Subtitle = styled("div")({
    padding: "4px",
    backgroundColor: theme.palette.secondary.main,
    fontSize: "0.8em",
  });

  return (
    <SprintContainer
      style={{
        gridColumnStart:
          props.sprint.startDate &&
          moment(props.solutionSprintStartDate).isAfter(
            moment(props.sprint.startDate)
          )
            ? 1
            : moment(props.sprint.startDate).diff(
                moment(props.solutionSprintStartDate),
                "days"
              ) + 1,
        gridColumnEnd:
          moment(props.sprint.endDate).diff(
            moment(props.solutionSprintStartDate),
            "days"
          ) < props.maxDays
            ? moment(props.sprint.endDate).diff(
                moment(props.solutionSprintStartDate),
                "days"
              ) + 1
            : props.maxDays + 1,
        gridRow: props.row,
      }}
      key={props.sprint.id}
    >
      <Title>{props.sprint.name}</Title>
      <Subtitle>
        {moment(props.sprint.startDate).format("DD.MM.YYYY") +
          " bis " +
          moment(props.sprint.endDate).format("DD.MM.YYYY")}
      </Subtitle>
      <Droppable droppableId={"sprint" + props.sprint.id}>
        {(provided, snapshot) => (
          <Sprint
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {props.sprint.items.map((key, index) => {
              const task: TaskItem = props.boardData.items[key]
                ? props.boardData.items[key]
                : {
                    key: key,
                    assignee: { name: "unknown", avatar: "#" },
                    content: "Issue not found",
                    labels: [],
                    linkTo: "#",
                    links: [],
                    status: {
                      name: "unknown",
                      color: "blue-gray",
                    },
                    teams: [],
                    type: "unknown",
                    sprintIds: [],
                  };
              return (
                <div
                  key={"boxOf" + task.key}
                  style={{ width: "300px", float: "left" }}
                  ref={props.refs.find((cell) => cell.key === task.key)?.ref}
                >
                  <Task
                    key={task.key}
                    task={task}
                    index={index}
                    highlightOptions={props.highlightOptions}
                    filterOptions={props.filterOptions}
                    isDragDisabled={props.isDragDisabled}
                  />
                </div>
              );
            })}
            {provided.placeholder}
          </Sprint>
        )}
      </Droppable>
    </SprintContainer>
  );
};
