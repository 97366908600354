import React, { FC, ReactElement } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Xwrapper } from "react-xarrows";
import { CellItem, MoveItem } from "../../types/Interface";
import { FEBoardData, ISelectedFieldOptions, TaskBoxRef } from "../App";
import { Arrows } from "../Arrows";
import { Row } from "./Row";

type IBoardProps = {
  boardData: FEBoardData;
  taskBoxRefs: TaskBoxRef[];
  moveRowUpFn: (key: string) => void;
  moveRowDownFn: (key: string) => void;
  moveItemFn: (move: MoveItem) => void;
  showDep: boolean;
  highlightOptions?: ISelectedFieldOptions;
  filterOptions?: ISelectedFieldOptions;
  isDragDisabled: boolean;
};

export const FeatureBoard: FC<IBoardProps> = (props): ReactElement => {
  const getCellByKey = (id: string): CellItem => {
    const cell = props.boardData.cells.find((element) => element.key === id);
    if (cell) {
      return cell;
    } else {
      throw new Error("Column not found");
    }
  };

  const onDragEnd = (result: DropResult) => {
    console.log(result);
    if (
      result.destination &&
      (result.destination.droppableId !== result.source.droppableId ||
        result.destination.index !== result.source.index)
    ) {
      //setActiveBackdrop(true);
      const destCell = getCellByKey(result.destination.droppableId);
      props.moveItemFn({
        targetCell: destCell.key,
        taskKey: result.draggableId,
        targetIndex: result.destination.index,
        targetRow: destCell.rowKey,
      });
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Xwrapper>
        <Row
          boardData={props.boardData}
          refs={props.taskBoxRefs}
          moveUp={props.moveRowUpFn}
          moveDown={props.moveRowDownFn}
          highlightOptions={props.highlightOptions}
          filterOptions={props.filterOptions}
          isDragDisabled={props.isDragDisabled}
        ></Row>
        <Arrows
          showDep={props.showDep}
          items={props.boardData.items}
          taskBoxRefs={props.taskBoxRefs}
        />
      </Xwrapper>
    </DragDropContext>
  );
};
